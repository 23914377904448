/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const query = params => axios({
    url: '/api/order/system/orderScanningDetail/createByUniqueCharacter',
    method: 'post',
    data: JSON.stringify(params) ,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const queryInfo = params => axios({
  url: '/api/order/system/orderScanningDetail/selectByUniqueCharacter',
  method: 'post',
  data: JSON.stringify(params) ,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

